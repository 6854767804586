<template>
  <div class="flex flex-row">
    <div class="w-1/4 px-2 print:hidden">
      <div class="w-full bg-white no-print shadow px-4 py-3">
        <vs-button v-if="filterSelectData" class="mt-3 w-full py-4 px-3" color="primary"
                   @click="clearFilterData">{{$t('ClearAllFilters').toUpperCase()}}
        </vs-button>
        <div class="py-2"></div>
        <!--            From TO Date Filters -->
        <label style="font-size: 12px; font-weight: bold">{{ $t('Date') }}
          <span
            @click="startDate = null;filterChanged()"
            class="span-clear cursor-pointer">{{ $t("Clear") }}</span>
        </label>
        <datepicker :placeholder="$t('Date')"
                    :disabled-dates="{from: new Date()}"
                    v-model="startDate" v-on:select="filterChanged"></datepicker>
        <div class="py-2"></div>
        <div class="w-full" v-if="$localStorage.get('irole') == 0 && users.length > 0">
          <label style="font-size: 12px; font-weight: bold">{{ $t('SelectUser') }}
            <span
              @click="selectedUser = null;filterChanged()"
              class="span-clear cursor-pointer">{{ $t("Clear") }}</span>
          </label>
          <vs-select
            autocomplete
            class="pr-4 w-full"
            v-model="selectedUser"
            v-if="users.length > 0"
            @input="onFilterChanged"
          >
            <vs-select-item key="-1" :value="-1" :text="$t('All')" />
            <template v-for="(user,index) in users">
              <vs-select-item :key="index" :value="user.user_id" :text="user.username" />
            </template>
          </vs-select>
        </div>


        <vs-button  v-if="filterSelectData" class="mt-3 w-full py-4 px-3" color="primary"
                    @click="filterChanged">{{$t('Filter').toUpperCase()}}
        </vs-button>
      </div>
    </div>
    <div class="w-3/4 print:w-full pt-16" v-if="reportData">
      <div class="h-screen flex w-full bg-img" v-if="reportsList && reportsList.length == 0">
        <div
          class="vx-col flex items-center justify-center flex-col sm:w-1/2 md:w-3/5 lg:w-3/4 xl:w-1/2 mx-auto text-center">
          <img src="@/assets/images/pages/svg_error.svg" :alt="$t('NoData')"
               class="mx-auto mb-4 max-w-full">
          <h1 class="sm:mx-0 mx-4 mb-12 text-3xl">{{$t('NoData')}}</h1>
        </div>
      </div>
      <div class="w-full flex flex-col justify-center " v-else>
        <div class="flex justify-center text-center">
          <h1 class="text-3xl font-bold border-b-2 border-black"> {{ $t('ZReport') }} </h1>
        </div>
        <div class="flex flex-col w-full justify-center mx-auto">
          <div class="w-96 mx-auto">
            <table class="w-full my-4 top-details-table">
              <tr>
                <td>
                  <p>{{ $t('PrintDate') }} : </p>
                </td>
                <td>
                  <p>{{ reportData.z_report_created }}</p>
                </td>
              </tr>
              <tr v-if="reportData.staff_name">
                <td>
                  <p>{{ $t('Employee') }} : </p>
                </td>
                <td>
                  <p>{{ reportData.staff_name }}</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>{{ $t('Date') }} : </p>
                </td>
                <td>
                  <p>{{ reportData.date }}</p>
                </td>
              </tr>
            </table>
          </div>
          <div class="w-96 mx-auto">
            <table class="z-report-data-table">
              <tr class="z-data-title">
                <td>{{ $t('CardName') }}</td>
              </tr>
              <tr class="z-data-title z-data-bottom-border">
                <td>{{ $t('DateOfSale') }}</td>
                <td>{{ $t('Number') }}</td>
                <td>{{ $t('Price') }}</td>
              </tr>
              <template v-for="item in reportsList">
                <tr class="z-data-content">
                  <td>
                    <p>
                      {{ item.product }}<span v-if="item.value"> - {{ item.value | germanNumberFormat }}</span>
                    </p>
                  </td>
                </tr>
                <tr class="z-data-content z-data-bottom-border">
                  <td>{{ item.created }}</td>
                  <td>{{ item.quantity }}</td>
                  <td>{{ item.selling_price | germanNumberFormat }}</td>
                </tr>
              </template>
              <tr class="z-data-title z-data-bottom-border">
                <td></td>
                <td>{{ $t('Total') }} : </td>
                <td>{{ reportData.z_report_total_amount | germanNumberFormat }}</td>
              </tr>
            </table>
          </div>
        </div>
        <div class="flex justify-center pt-4">
          <vs-button class="mt-3 py-4 px-3" color="primary"
                     @click="printPage">{{$t('Print').toUpperCase()}}
          </vs-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker'
import moment from 'moment'

export default {
  name: "ZReportView",
  data() {
    return {
      reportData: null,
      reportsList: null,
      selectedUser: -1,
      filterSelectData: {},
      startDate: null,
    }
  },
  components: {
    Datepicker,
  },
  computed: {
    users() {
      return this.$store.state.user.users
    },
    activeUserInfo() {
      return this.$store.state.AppActiveUser
    },
    disabledDates() {
      if (!this.startDate) {
        return {
          from: new Date(),}
      }
      return {
        to: this.startDate,
        from: new Date(),
      }
    },
  },
  methods: {
    clearFilterData() {
      this.filterSelectData = {}
      this.startDate = null
      this.selectedUser = -1;
      this.filterChanged()
    },

    onFilterChanged() {
      this.filterChanged()
    },
    getFilters() {
      const filter = {}
      // Object.keys(this.filterSelectData).forEach((key) => {
      //   if (this.filterSelectData[key] != null) {
      //     filter[key] = this.filterSelectData[key].replace(key, '')
      //   }
      // })
      if (this.startDate) {
        filter.date = moment(this.startDate).format('yyyy-MM-DD')
      }
      if (this.selectedUser != -1) {
        filter.user_id = this.selectedUser
      }
      return filter
    },
    filterChanged() {
      this.fetchZReport()
    },
    fetchZReport() {
      let filter = this.getFilters();
      this.$vs.loading()
      return this.$store.dispatch('reports/fetchZReport', { filter })
        .then((data) => {
          this.reportData = data;
          this.reportsList = this.reportData.z_report;
          this.$vs.loading.close()
          this.errorFetching = false
        })
        .catch((error) => {
          this.$vs.loading.close()
          this.errorFetching = true
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: error.response.data.message || error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },

    loadUsers(isMore = false) {
      this.$store.dispatch('user/fetchSellerUsers', { isMore })
        .then((data) => {
        })
        .catch((err) => {
          this.errorFetching = true
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: err.response.data.message || err.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },
    printPage() {
      if(this.reportData.filter.start_date) {
        let startDate = this.reportData.filter.start_date.replace("-", "")
        if (this.reportData.staff_name) {
          document.title = `z-report_${this.reportData.staff_name}_${startDate}`;
        } else {
          document.title = `z-report_${startDate}`;
        }
      } else {
        if (this.reportData.staff_name) {
          document.title = `z-report_${this.reportData.staff_name}`;
        } else {
          document.title = `z-report`;
        }
      }
      window.print();
    }
  },
  mounted() {
    this.startDate = new Date()
    if (localStorage.getItem('irole') == 0) this.loadUsers()
    this.fetchZReport()
  }
}
</script>

<style scoped lang="scss">
.top-details-table {
  td {
    padding: 4px 0px;
  }
}
.z-report-data-table {
  @apply border-2 border-black w-full;

  td {
    @apply px-1 py-1;
  }
  .z-data-title {
    @apply font-bold;
  }
  .z-data-bottom-border {
    @apply border-b-2 border-black w-full;
  }
}
</style>
